<template>
  <v-navigation-drawer app v-model="drawer" :width="width" clipped>
    <template v-if="left.list">
      <template class="mx-auto" max-width="300" tile>
        <v-list
          dense
          v-for="(item, gi) in left.list.items"
          :key="'g_' + item.text + '_' + gi"
          class="nav-left-main"
        >
          <v-subheader v-text="item.text"></v-subheader>
          <v-divider></v-divider>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
              v-for="(subitem, i) in item.subitems"
              :key="item.text + '_' + subitem.text + '_' + i"
              :to="subitem.path"
              active-class="highlighted"
              :class="subitem.path === $route.path ? 'highlighted' : ''"
              @click="choose(subitem)"
            >
              <v-list-item-icon>
                <v-icon v-text="subitem.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="subitem.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </template>
    <template v-if="left.toc">
      <template class="mx-auto" max-width="800" tile>
        <v-treeview
          :open.sync="tocOpenNodes"
          :active.sync="tocActiveNodes"
          :items="left.toc.items"
          class="toc"
          v-on:update:active="onTocActive"
          item-key="id"
          activatable
          color="warning"
          dense
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="item.children"> mdi-file-multiple-outline </v-icon>
            <v-icon v-else> mdi-file-document-outline </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <v-sheet>{{ item.name }}</v-sheet>
          </template>
        </v-treeview>
      </template>
    </template>
    <template v-if="left.vocab">
      <template class="mx-auto" max-width="800" tile>
        <v-card flat class="transparent vocab-stats">
          <v-progress-linear
            v-model="left.vocab.knowledge"
            v-if="left.vocab.knowledge > 0"
          ></v-progress-linear>

          <v-subheader>ស្ថិតិនៃវចនៈប័ណ្ណ</v-subheader>
          <v-divider></v-divider>

          <v-list-item v-for="info in left.vocab.learnInfos" :key="info[0]">
            <v-list-item-title>{{ info[0] }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              info[1]
            }}</v-list-item-subtitle>
          </v-list-item>

          <v-divider></v-divider>
          <v-subheader>ធ្វើការកំណត់</v-subheader>
          <v-divider></v-divider>
          <v-card-text>
            <v-slider
              v-model="left.vocab.boxStart"
              label="ចំណុចផ្តើមនៃវចនៈ"
              min="1"
              :max="left.vocab.maxStart"
              thumb-label="always"
            ></v-slider>
          </v-card-text>
          <v-card-text>
            <v-slider
              v-model="left.vocab.boxLength"
              label="ចំណុចចុងនៃវចនៈ"
              min="1"
              :max="left.vocab.maxLength"
              color="red"
              thumb-label="always"
            ></v-slider>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="orange"
              @click="
                left.vocab.update({
                  start: left.vocab.boxStart,
                  len: left.vocab.boxLength,
                })
              "
              text
              >ធ្វើកំណត់</v-btn
            >
            <v-btn color="deep-purple accent-4" @click="left.vocab.reset()" text
              >ធ្វើថ្មី</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </template>
    <template v-if="left.flashcard">
      <template class="mx-auto" max-width="800" tile>
        <v-card flat class="transparent flashcard-stats">
          <v-progress-linear
            v-model="left.flashcard.knowledge"
            v-if="left.flashcard.knowledge > 0"
          ></v-progress-linear>

          <v-subheader>ស្ថិតិនៃបញ្ហាប័ណ្ណ</v-subheader>
          <v-divider></v-divider>

          <v-list-item v-for="info in left.flashcard.learnInfos" :key="info[0]">
            <v-list-item-title>{{ info[0] }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              info[1]
            }}</v-list-item-subtitle>
          </v-list-item>

          <v-divider></v-divider>
          <v-subheader>ធ្វើការកំណត់</v-subheader>
          <v-divider></v-divider>
          <v-card-text>
            <v-slider
              v-model="left.flashcard.boxStart"
              label="ចំណុចផ្តើមនៃបញ្ហា"
              min="1"
              :max="left.flashcard.maxStart"
              thumb-label="always"
            ></v-slider>
          </v-card-text>
          <v-card-text>
            <v-slider
              v-model="left.flashcard.boxLength"
              label="ចំណុចចុងនៃបញ្ហា"
              min="1"
              :max="left.flashcard.maxLength"
              color="red"
              thumb-label="always"
            ></v-slider>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="orange"
              @click="
                left.flashcard.update({
                  start: left.flashcard.boxStart,
                  len: left.flashcard.boxLength,
                })
              "
              text
              >ធ្វើកំណត់</v-btn
            >
            <v-btn
              color="deep-purple accent-4"
              @click="left.flashcard.reset()"
              text
              >ធ្វើថ្មី</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </template>
  </v-navigation-drawer>
</template>


<style lang="scss">
.toc {
  font-size: 0.875em !important;
}
.nav-left-main .v-list-item__title{
  line-height: 1.2rem!important;
}
</style>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "LeftBar",
  data: () => ({
    selectedItem: 1,
    listItems: [
      { text: "ព្រះត្រៃបិដក", icon: "mdi-clock", path: "/tipitaka/vinaya" },
      { text: "ភាសាបាលី", icon: "mdi-account", path: "/" },
    ],
  }),
  computed: {
    ...mapState(["left"]),
    width() {
      if (this.left.toc) return 500;
      return 300;
    },
    tocOpenNodes: {
      get: function () {
        return this.$store.state.tocOpenNodes;
      },
      set: function (openNodes) {
        this.setTocOpenNodes(openNodes);
      },
    },
    tocActiveNodes: {
      get: function () {
        return this.$store.state.tocActiveNodes;
      },
      set: function (activeNodes) {
        this.setTocActiveNodes(activeNodes);
      },
    },
    drawer: {
      get: function () {
        return this.$store.state.drawer;
      },
      set: function (newValue) {
        this.setDrawer(newValue);
      },
    },
  },
  methods: {
    ...mapActions([
      "setDrawer",
      "setTitle",
      "setTocActiveNodes",
      "setTocOpenNodes",
    ]),
    goto(path) {
      this.$router.push(path).catch((error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(error);
        }
      });
    },
    choose(item) {
      this.goto(item.path);
    },
    onTocActive() {
      this.setTocActiveNodes(this.tocActiveNodes);
    },
  },
};
</script>