<template>
  <v-container>
    <v-spacer />
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      :search-input.sync="search"
      hide-selected
      label="ជំរើសណាមួយទៅស្វែងរក"
      multiple
      small-chips
      solo
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">បង្កើត</span>
          <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item.text }}
          </span>
          <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-chip :color="`${item.color} lighten-3`" dark label small>
          {{ item.text }}
        </v-chip>
      </template>
      <template v-slot:append>
        <v-btn height="auto" text @click="clickHandler">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-combobox>
    <v-spacer></v-spacer>
  </v-container>
</template>
<script>
export default {
  name: "SearchBar",
  data() {
    return {
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
      items: [],
      nonce: 1,
      model: [],
      search: null,
    };
  },

  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;
      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },
  methods: {
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
      );
    },
    clickHandler(){
      let query = []
      this.model.forEach(e=>query.push(e.text))
      this.$emit('on-search', query)
    }
  },
};
</script>
