import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const state = () => ({
    drawer: false,
    isHeaderBar: true,
    footer: false,
    closed: false,
    toc: false,
    tocActiveNodes: [],
    tocOpenNodes: [],
    title: "កម្ពុជាន្តេវាសិក",
    subtitle: "",
    icon: "mdi-eye",
    header: {},
    left: {},
    headerTabIndex: 0,
    auth: {},
    defaultScript: "samanha-aksara-11-13",
    settings: {}
})

const mutations = {
    mutateDrawer(state, drawer) {
        state.drawer = drawer
    },
    mutateIsHeaderBar(state, isHeaderBar) {
        state.isHeaderBar = isHeaderBar
    },
    mutateFooter(state, footer) {
        state.footer = footer
    },
    mutateClosed(state, closed) {
        state.closed = closed
    },
    mutateToc(state, toc) {
        state.toc = toc
    },
    mutateTocActiveNodes(state, tocActiveNodes) {
        state.tocActiveNodes = tocActiveNodes
    },
    mutateTocOpenNodes(state, tocOpenNodes) {
        state.tocOpenNodes = tocOpenNodes
    },
    mutateTitle(state, title) {
        state.title = title
    },
    mutateSubtitle(state, subtitle) {
        state.subtitle = subtitle
    },
    mutateIcon(state, icon) {
        state.icon = icon
    },
    mutateHeader(state, header) {
        state.header = header || {}
    },
    mutateLeft(state, left) {
        state.left = left
    },
    mutateHeaderTabIndex(state, tabIndex) {
        state.headerTabIndex = tabIndex
    },
    mutateAuth(state, auth) {
        state.auth = auth
    },
    mutateDefaultScript(state, defaultScript) {
        state.defaultScript = defaultScript
    },
    mutateSettings(state, settings) {
        state.settings = settings
    },
}

const actions = {
    setDrawer({ commit }, newDrawer) {
        commit("mutateDrawer", newDrawer)
    },
    setIsHeaderBar({ commit }, newIsHeaderBar) {
        commit("mutateIsHeaderBar", newIsHeaderBar)
    },
    setFooter({ commit }, newFooter) {
        commit("mutateFooter", newFooter)
    },
    setClosed({ commit }, closed) {
        commit("mutateClosed", closed)
    },
    setToc({ commit }, toc) {
        commit("mutateToc", toc)
    },
    setTocActiveNodes({ commit }, tocActiveNodes) {
        commit("mutateTocActiveNodes", tocActiveNodes)
    },
    setTocOpenNodes({ commit }, tocOpenNodes) {
        commit("mutateTocOpenNodes", tocOpenNodes)
    },
    setTitle({ commit }, newTitle) {
        commit("mutateTitle", newTitle)
    },
    setSubtitle({ commit }, newSubtitle) {
        commit("mutateSubtitle", newSubtitle)
    },
    setIcon({ commit }, icon) {
        commit("mutateIcon", icon)
    },
    setHeader({ commit }, header) {
        commit("mutateHeader", header)
    },
    setLeft({ commit }, left) {
        commit("mutateLeft", left)
    },
    setHeaderTabIndex({ commit }, tabIndex) {
        commit("mutateHeaderTabIndex", tabIndex)
    },
    setAuth({ commit }, auth) {
        commit("mutateAuth", auth)
    },
    setDefaultScript({ commit }, defaultScript) {
        commit("mutateDefaultScript", defaultScript)
    },
    setSettings({ commit }, settings) {
        commit("mutateSettings", settings)
    }
}

const getters = {
    isTokenExpired: (state) => {
        const auth = state.auth;
        let authExpireAt = auth.expire ?? 0;
        let currentTime = new Date().getTime()
        return (currentTime > authExpireAt)
    },
    access_token: (state) => state.auth.access_token,
    defaultScript: (state) => state.defaultScript
}


const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    plugins: [createPersistedState()]
});

export default store;