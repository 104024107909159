import axios from 'axios';
import store from '@/plugins/store';

const baseUrl = `${process.env.VUE_APP_API_URL}`;

const renewTokens = async function () {
    try {
        const response = await axios.post(`${baseUrl}api/tokens`)
        let expireAt = new Date().getTime()

        expireAt += (response.data.expires_in * 60 * 1000)

        const authData = {
            access_token: response.data.access_token,
            expire: expireAt
        }
        store.dispatch("setAuth", authData);
    } catch (error) {
        console.error(error)
    }
}
const revokeTokens = async function () {
    try {
        const authData = {}
        store.dispatch("setAuth", authData);
    } catch (error) {
        console.error(error)
    }
}

const setup = async function () {
    try {
        if (store.getters.isTokenExpired || !store.getters.access_token) {
            await renewTokens();
        }
    } catch (error) {
        console.error(error)
        return {}
    }
}
const isAuthenticated = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_APP_URL}.auth/me`)
        const me = response.data;
        return (!me.clientPrincipal) ? false : true;
    } catch (error) {
        return false
    }
}
const me = async () => {
    const meInfo = {
        "identityProvider": "kambujaantevasika",
        "userDetails": "user@kambujaantevasika"
    }
    try {
        const response = await axios.get(`${process.env.VUE_APP_APP_URL}.auth/me`)
        const me = response.data;
        return (!me.clientPrincipal) ? meInfo : me.clientPrincipal;
    } catch (error) {
        return meInfo
    }
}

const AuthService = {
    setup,
    renewTokens,
    revokeTokens,
    isAuthenticated,
    me
}

export default AuthService;
