import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            header: {
                title: "កម្ពុជាន្តេវាសិក",
            }
        },
        components: {
            default: () => import(/* webpackChunkName: "tipitaka" */ '../components/pages/HomePage')
        }
    },
    {
        path: '/tipitaka/:mode?/:id?',
        name: 'tipitaka',
        meta: {
            header: {
                title: "ព្រះត្រៃបិដក",
                tabs: [
                    { title: "ព្រះវិន័យបិតក", path:"/tipitaka/vinaya" },
                    { title: "ព្រះសុតន្តបិតក", path:"/tipitaka/sutanta" },
                    { title: "ព្រះអភិធម្មបិតក", path:"/tipitaka/abhidhamma" },
                ]
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "tipitaka" */ '../components/pages/tipitaka/TipitakaHome')
        }
    },
    {
        path: '/palibhasa/:mode?/:id?',
        name: 'palibhasa',
        meta: {
            header: {
                title: "បាលីភាសា",
                tabs: [
                    { title: "មេរៀន", path:"/palibhasa/lesson" },
                    { title: "វេយ្យាករណ៏", path:"/palibhasa/grammar" },
                    { title: "ពាក្យគណ:", path:"/palibhasa/words" },
                ]
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "palibhasa" */ '../components/pages/palibhasa/PalibhasaHome')
        }
    },
    {
        path: '/khemarabhasa/:mode?/:id?',
        name: 'khemarabhasa',
        meta: {
            header: {
                title: "ខេមរៈភាសា",
                tabs: [
                    { title: "មេរៀន", path:"/khemarabhasa/lesson" },
                    { title: "វេយ្យាករណ៏", path:"/khemarabhasa/grammar" },
                    { title: "ពាក្យគណ:", path:"/khemarabhasa/words" },
                ]
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "khemarabhasa" */ '../components/pages/khemarabhasa/KhemarabhasaHome')
        }
    },
    {
        path: '/sanskritbhasa/:mode?/:id?',
        name: 'sanskritbhasa',
        meta: {
            header: {
                title: "សំស្ក្រឹតភាសា",
                tabs: [
                    { title: "មេរៀន", path:"/sanskritbhasa/lesson" },
                    { title: "វេយ្យាករណ៏", path:"/sanskritbhasa/grammar" },
                    { title: "ពាក្យគណ:", path:"/sanskritbhasa/words" },
                ]
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "sanskritbhasa" */ '../components/pages/sanskritbhasa/SanskritbhasaHome')
        }
    },
    {
        path: '/potthaka/:mode?/:id?',
        name: 'potthaka',
        
        meta: {
            header: {
                title: "បោត្ថក",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "potthaka" */ '../components/pages/potthaka/PotthakaHome')
        }
    },
    {
        path: '/base64',
        name: 'base64',
        meta: {
            header: {
                title: "គោល៦៤ រូបភាព",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "base64" */ '../components/pages/base64/Base64Home')
        }
    },
    {
        path: '/vocab/:mode?/:id?',
        name: 'vocab',
        
        meta: {
            header: {
                title: "ចំណាំពាក្យវចនៈ",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "vocab" */ '../components/pages/vocab/VocabHome')
        }
    },
    {
        path: '/flashcard/:mode?/:id?',
        name: 'flashcard',
        
        meta: {
            header: {
                title: "ចំណាំបញ្ហា",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "flashcard" */ '../components/pages/flashcard/FlashcardHome')
        }
    },
    {
        path: '/conversation/:mode?/:id?',
        name: 'conversation',
        
        meta: {
            header: {
                title: "សន្ទនា",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "conversation" */ '../components/pages/conversation/ConversationHome')
        }
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            header: {
                title: "ធ្វើកំណត់ទុក",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "settings" */ '../components/pages/settings/SettingHome')
        }
    },
    {
        path: '/kmerscript',
        name: 'kmerscript',
        meta: {
            header: {
                title: "ក្មេរ៑អក្សរ បំលែង",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "settings" */ '../components/pages/kmerscript/KmerScriptHome')
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            header: {
                title: "Login",
            }
        },
        props: {
            default: true
        },
        components: {
            default: () => import(/* webpackChunkName: "settings" */ '../components/pages/LoginPage')
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes, // short for `routes: routes`
});

export default router;