import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import store from './plugins/store'
import AuthService from './services/auth.service'

Vue.config.productionTip = false

import './styles/main.scss'

router.beforeEach(async (to, from, next) => {
  let newHeader = Object.assign({ ...store.state.header }, {});
  const isAuthenticated = await AuthService.isAuthenticated();
  let isInLogin = to.name === null && to.path === '/login';

  if (to.path === '/login') {
    store.dispatch("setIsHeaderBar", false)
  } else {
    store.dispatch("setIsHeaderBar", true)
  }

  if (!isInLogin && !isAuthenticated && process.env.NODE_ENV === 'production' && to.path !== '/login') {
    window.location = '/login'
  }

  document.title = store.state.title;
  // store.dispatch("setHeader", {
  //   prominent: false
  // });

  if (to.matched.some(record => record.meta.header)) {
    newHeader = Object.assign(newHeader, { ...to.meta.header });
    store.dispatch("setHeader", newHeader);
  } else {
    newHeader.prominent = false;
    newHeader.showClose = false;
    newHeader.showDrawer = true;

    store.dispatch("setHeader", newHeader);
  }

  store.dispatch("setFooter", true);

  next()
})

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
