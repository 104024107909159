<template>
  <v-app :class="scriptClass" :pali-class="paliClass" :khmer-class="khmerClass">
    <header-bar v-if="isHeaderBar" />
    <left-bar v-if="drawer" />

    <v-main>
      <router-view />
    </v-main>
    
    <footer-bar v-if="footer"/>
  </v-app>
</template>

<script>
import HeaderBar from "./components/atoms/HeaderBar";
import LeftBar from "./components/atoms/LeftBar";
import { mapState } from "vuex";
import FooterBar from "./components/atoms/FooterBar.vue";

export default {
  name: "App",
  components: {
    HeaderBar,
    LeftBar,
    FooterBar,
  },
  computed: {
    ...mapState(["drawer", "isHeaderBar", "footer", "settings"]),
    scriptClass() {
      let settings = this.settings || {};
      if (Object.keys(settings).length === 0 && settings.constructor === Object){
        settings = {
          "samanhaScript": "samanha-aksara-11-13",
        };
      }
      return `${settings.samanhaScript}`;
    },
    paliClass() {
      let settings = this.settings || {};
      if (Object.keys(settings).length === 0 && settings.constructor === Object){
        settings = {
          "paliScript": "pali-aksara-11-13",
        };
      }
      return `${settings.paliScript}`;
    },
    khmerClass() {
      let settings = this.settings || {};
      if (Object.keys(settings).length === 0 && settings.constructor === Object){
        settings = {
          "khmerScript": "pali-aksara-11-13",
        };
      }
      return `${settings.khmerScript}`;
    },
  },
  data: () => ({}),
};
</script>
