//let a_vowel = "អ" // U+17A2
let virama = "ឣ" // U+17A3
let virama2 = "៑"
let leg = "្"
let nigahita = "ំ"
//let visarga = "ះ"
//let samyok = "័"
//let khand = "។"
let vannayuti = ["ះ", "ៈ", "ៗ", "៙", "។", "៕", "៚", "-", "៘", "៉", "៊", "៍", "៌", "់", "័", "៏", "៎"]

let nissaya_vowels = ["", "ា", "ិ", "ី", "ុ", "ូ", "្ឫ", "្ឬ", "្ឭ", "្ឮ", "េ", "ៃ", "ោ", "ៅ", "ែ", "ឹ", "ឺ", "ើ", "ឿ", "្ឲ", "្ឨ", "ៀ", "ួ", "្ឪ"]
let visuddha_vowels = ["ឣ", "ឤ", "ឥ", "ឦ", "ឧ", "ឩ", "ឫ", "ឬ", "ឭ", "ឮ", "ឯ", "ឰ", "ឱ", "ឳ", "ឣែ", "ឣឹ", "ឣឺ", "ឣើ", "ឣឿ", "ឲ", "ឨ", "ឣៀ", "ឣួ", "ឪ"]

let number_km = "០១២៣៤៥៦៧៨៩"
let number_roman = "0123456789"

let a_paat = "ₐ"
let a_muak = "ᵃ"

let consonants = [
    "ក",
    "ខ",
    "គ",
    "ឃ",
    "ង",
    "ច",
    "ឆ",
    "ជ",
    "ឈ",
    "ញ",
    "ដ",
    "ឋ",
    "ឌ",
    "ឍ",
    "ណ",
    "ត",
    "ថ",
    "ទ",
    "ធ",
    "ន",
    "ប",
    "ផ",
    "ព",
    "ភ",
    "ម",
    "យ",
    "រ",
    "ល",
    "វ",
    "ឝ",
    "ឞ",
    "ស",
    "ហ",
    "ឡ",
    "អ",
    "ំ", "ះ", "ៈ", "ៗ", "៙", "។", "៕", "៚", "-", "៘", "៉", "៊", "៍", "៌", "់", "័", "៏", "៎"
]


let alphabets = {
    pali: visuddha_vowels.concat(consonants).concat(number_km.split('')),
    iakt: [
        "a", "ā", "i", "ī", "u", "ū", "ṛ", "ṝ", "ḻ", "ḹ", "e", "ai", "o", 
        "au", "ê", "iṃ", "ȉ", "ế", "ȅ", "ö", "ȏ", "īa", "ūa", "õ", "k", "kh", 
        "g", "gh", "ṅ", "c", "ch", "j", "jh", "ñ", "ṭ", "ṭh", "ḍ", "ḍh", 
        "ṇ", "t", "th", "d", "dh", "n", "p", "ph", "b", "bh", "m", "y", 
        "r", "l", "v", "ś", "ṣ", "s", "h", "ḷ", "ạ", 
        "ṃ", "ḥ", "ḣ", "ä", "ḱ", ".", "ŀ", "ǧ", "-", "ṕ", 
        "ȁ", "ã", "å", "ȑ", "á", "ă", "â", "ȧ"
    ].concat(number_roman.split('')),
    IAKT: [
        "A", "Ā", "I", "Ī", "U", "Ū", "Ṛ", "Ṝ", "Ḻ", "Ḹ", "E", "AI", "O", 
        "AU", "Ê", "IṂ", "Ȉ", "Ế", "Ȅ", "Ö", "Ȏ", "ĪA", "ŪA", "Õ", "K", "KH", 
        "G", "GH", "Ṅ", "C", "CH", "J", "JH", "Ñ", "Ṭ", "ṬH", "Ḍ", "ḌH", 
        "Ṇ", "T", "TH", "D", "DH", "N", "P", "PH", "B", "BH", "M", "Y", 
        "R", "L", "V", "Ś", "Ṣ", "S", "H", "Ḷ", "Ạ", 
        "Ṃ", "Ḥ", "Ḣ", "Ȑ", "Ḱ", ".", "Ŀ", "Ǧ", "-", "Ṕ", 
        "Ȁ", "Ã", "Å", "Ä", "Á", "Ă", "Â", "Ȧ"
    ].concat(number_roman.split('')),
}

// Make trie, given a table like {'a':'अ', 'A':'आ', 'ai':'ऐ', 'au':'औ' }
const maketrie = function (table) {
    let root = {}
    
    for (let s in table) {
        let where = root
        for (let i = 0; i < s.length; ++i) {
            if (where[s[i]] === undefined) where[s[i]] = {}
            where = where[s[i]]
        }
        where.label = table[s]
    }

    return root
}
//Convert a string using a labelled trie
const convert = function (s, trie, escape_characters) {
    let angList = ['ŋ', 'ṁ']
    let out = ""
    let where = trie,
        d = 0,
        a = "",
        b = 0 //d = depth, a = parsed part, b = length of unparsed part
    let i = 0

    while (i < s.length) {
        let c = s[i]

        
        if (angList.includes(c)) {
            c = 'ṃ'
        } else if (c === 'ɲ') {
            c = 'ṅ'
        } else if (c === 'Ɲ') {
            c = 'Ṅ'
        }

        ++i
        ++d
        ++b
        if (where[c] !== undefined) {
            //If there is a child in the trie, just follow it
            where = where[c]
            if (where.label !== undefined) {
                a = where.label
                b = 0
            }
        } else {
            // else, go back to the root
            if (b == d) {
                //\"Parse\" the first character
                a = s[i - b]
                --b
            }
            if (!contains(escape_characters, a)) {
                out += a
            }
            
            i -= b
            where = trie
            d = 0
            a = ""
            b = 0
        }
    }
    if (!contains(escape_characters, a)) {
        out += a
    }
    out += s.substr(i - b)
    return out
}

//////////////////////////////////////////////////////////////////////
//Make object like {'a':'अ', 'A':'आ', 'ai':'ऐ', 'au':'औ', 'k':'क्'}
const to_pali = function (alphabet) {
    let ret = {}
    for (let i in alphabet) {
        let rhs = alphabets["pali"][i] // + (i >= visuddha_vowels.length ? virama : '');

        if (typeof alphabet[i] == "string") ret[alphabet[i]] = rhs
        else {
            for (let c in alphabet[i]) ret[alphabet[i][c]] = rhs
        }
    }
    return ret
}

//Make object like {'अ':'a', 'आ':'A', 'ऐ':'ai', 'औ':'au' }

const from_pali = function (alphabet) {
    let ret = {}
    for (let i in alphabet) {
        let lhs = alphabets["pali"][i] // + (i >= visuddha_vowels.length ? virama : '');
        if (typeof alphabet[i] == "string") ret[lhs] = alphabet[i]
        else ret[lhs] = alphabet[i][0]
    }
    return ret
}
const to_nissaya = function (s) {
    let t = ""
    s = s.replace(RegExp(a_paat, "g"), "្អ")
    s = s.replace(RegExp(a_muak, "g"), "អ្")

    //let engList = [visuddha_vowels[2], visuddha_vowels[3]]
    const legals = consonants.concat(visuddha_vowels).concat(nissaya_vowels).concat(leg)
    //const vowels = visuddha_vowels.concat(nissaya_vowels)
    const nisayaNigahita = [nigahita].concat(nissaya_vowels)

    for (let i = 0; i < s.length; ++i) {
        let c = s[i]
        let nc = s[i + 1]
        let pc = s[i - 1]
        
        if (contains(visuddha_vowels, c) && nc === nigahita && (!pc || pc.match(/\s/gi))) {
            t += visuddha_vowels[0]
            t += nissaya_vowels[visuddha_vowels.indexOf(c)]
            continue
        }

        // if current c, is nisaya & nigahita, and previous c is null or empty, then add AH
        if (contains(nisayaNigahita, c) && (!pc || pc.match(/\s/gi))) {
            t += visuddha_vowels[0]
        }

        t += c

        const ls1 = [nigahita].concat(vannayuti)

        if (
            contains(consonants, c) &&
            contains(consonants, nc) &&
            ls1.includes(nc) === false &&
            ls1.includes(c) === false
        ) {
            t += leg
        } else if (
            contains(consonants, c) &&
            !contains(legals, nc) &&
            ls1.includes(nc) === false &&
            ls1.includes(c) === false
        ) {
            t += virama2
        }
    }
    s = t

    t = ""
    for (let i = 0; i < s.length; i++) {
        let c = s[i]
        //let nc = s[i + 1]
        let pc = s[i - 1]
        let j = visuddha_vowels.indexOf(c)
        if (j >= 0) {
            let isChangingToNissaya = false
            //if (consonants.includes(pc) || vannayuti_vi_vowels.includes(pc) || vannayuti_ni_vowels.includes(pc)) {
            if (consonants.includes(pc)) {

                const ls1 = [nigahita, leg, virama, virama2].concat(vannayuti)
                if (!ls1.includes(pc)) {
                    isChangingToNissaya = true
                }
            }
            
            if (isChangingToNissaya) {
                c = nissaya_vowels[j]
            } else {
                if (j === 0) {
                    c = "អ"
                } else if (j === 1) {
                    c = "អា"
                }
            }
        }

        t += c
    }
    s = t
    return s
}
const to_visuddha = function (s) {
    //Next, replace implicit 'a' with VIRAMA+A
    let t = ""

    for (let i = 0; i < s.length; ++i) {
        let c = s[i]
        let nc = s[i + 1]

        if (c === leg) {
            if (nc == 'អ') {
                t += a_paat
            }
            continue
        } else if (c === 'អ' && nc == leg) {
            t += a_muak
            continue
        } else if (c === virama2) {
            if (nc != ' ') t += ' '
            continue
        }

        t += c

        let k = -1;
        k = nissaya_vowels.indexOf(nc)
        if (k >= 0){
            t += visuddha_vowels[k]
            i += 1
            continue
        }

        const ls1 = [nigahita].concat(vannayuti)
        const ls2 = [virama, virama2, leg, a_paat]

        if (
            contains(consonants, c) &&
            ls2.includes(nc) === false &&
            ls1.includes(c) === false
        ) {
            t += virama
        }   
    }

    return t
}

const contains = function (arr, obj) {
    if (!arr) return false
    let i = arr.length
    while (i--) {
        if (arr[i] === obj) {
            return true
        }
    }
    return false
}

let trie_roman = maketrie(from_pali(alphabets.iakt))
let trie_pali_l = maketrie(to_pali(alphabets.iakt))
let trie_pali_u = maketrie(to_pali(alphabets.IAKT))

const toRoman = function (s) {
    let t = to_visuddha(s)
    t = convert(t, trie_roman, ['ạ'])

    return t
}
const toKhmer = function (s) {
    //s = toRoman(s)
    let t = convert(s, trie_pali_u)
    t = convert(t, trie_pali_l)
    t = to_nissaya(t)
    return t
}
const toRomanNumber = function (n) {
    let s = `${n}`
    for (var i = 0; i < number_km.length; i++) {
        let pattern = new RegExp(number_km[i], 'g')
        s = s.replace(pattern, number_roman[i]);
    }
    return s;
}
const toKhmerNumber = function (n) {
    let s = `${n}`
    for (var i = 0; i < number_km.length; i++) {
        let pattern = new RegExp(number_roman[i], 'g')
        s = s.replace(pattern, number_km[i]);
    }
    return s;
}

export const KmerScript = {
    toRoman,
    toRomanNumber,
    toKhmer,
    toKhmerNumber
}