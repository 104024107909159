<template>
  <v-footer color="cyan lighten-1" dark padless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ link }}
      </v-btn>
      <v-col class="cyan lighten-2 py-4 text-center white--text" cols="12">
        {{ appYear }} — <strong>កម្ពុជាន្តេវាសិក</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>


<style lang="scss">
.camnangjeng-2 {
  font-size: 0.875rem !important;
  line-height: 1.375rem;
}
</style>

<script>
import { KmerScript } from "@/helpers/kmer-script-helper";

export default {
  name: "FooterBar",
  data: () => ({
    links: ["ទំព័រមុខ", "អំពីគណៈយើង", "ទំនាក់ទំនង"],
  }),
  computed: {
    appYear() {
      return KmerScript.toKhmerNumber(new Date().getFullYear());
    },
  },
};
</script>