import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import '@mdi/font/css/materialdesignicons.css'
import '../../public/fonts/kambuja-aksara-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons:{
        iconfont: 'mdi' || 'kma', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    }
});
