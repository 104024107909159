import showdown from "showdown";
import hljs from 'highlight.js';

export const componentHook = {
    methods: {
        editHeader(newHeader) {
            let header = { ...this.header, ...newHeader }
            this.setHeader(header)
        },
        editLeft(newLeft) {
            let left = {}
            if (!newLeft || newLeft.resetList) {
                newLeft = newLeft || {}
                newLeft.list = {
                    items: [
                        {
                            text: "បណ្ណាល័យ",
                            subitems: [
                                {
                                    text: "ព្រះត្រៃបិដក",
                                    icon: "mdi-leaf",
                                    path: "/tipitaka/vinaya",
                                },
                                {
                                    text: "ខេមរភាសា",
                                    icon: "kma-kha",
                                    path: "/khemarabhasa/lesson",
                                },
                                {
                                    text: "បាលីភាសា",
                                    icon: "kma-pa",
                                    path: "/palibhasa/lesson",
                                },
                                {
                                    text: "សំស្ក្រឹតភាសា",
                                    icon: "kma-sa",
                                    path: "/sanskritbhasa/lesson",
                                },
                                {
                                    text: "សៀវភៅ",
                                    icon: "mdi-book-open-variant",
                                    path: "/potthaka/list",
                                },
                                {
                                    text: "សន្ទនា",
                                    icon: "mdi-account-multiple-outline",
                                    path: "/conversation/list",
                                },
                            ],
                        },
                        {
                            text: "រៀនចំណាំ",
                            subitems: [
                                {
                                    text: "ចំណាំពាក្យវចនៈ",
                                    icon: "mdi-brain",
                                    path: "/vocab/list",
                                },
                                {
                                    text: "ចំណាំបញ្ហា",
                                    icon: "mdi-head-question-outline",
                                    path: "/flashcard/list",
                                }
                            ],
                        },
                        {
                            text: "ឧបករណ៏",
                            subitems: [
                                {
                                    text: "គោល៦៤រូបភាព",
                                    icon: "mdi-file-image",
                                    path: "/base64",
                                },
                                {
                                    text: "បំលែងអក្សរក្មេរ៑",
                                    icon: "kma-kha",
                                    path: "/kmerscript",
                                }
                            ],
                        },
                    ],
                };
            } 
            left = { ...this.left, ...newLeft }
            this.setLeft(left);
        },
        groupBy(arr, criteria) {
            return arr.reduce(function (obj, item) {

                // Check if the criteria is a function to run on the item or a property of it
                var key = typeof criteria === 'function' ? criteria(item) : item[criteria];

                // If the key doesn't exist yet, create it
                if (!Object.prototype.hasOwnProperty.call(obj, key)) {
                    obj[key] = [];
                }

                // Push the value to the object
                obj[key].push(item);

                // Return the object to the next item in the loop

                return obj;

            }, {});
        },
        renderText(text, wrapDiv) {
            if (!text) return "";

            let mySpan = {
                type: 'lang',
                regex: '\\[{1}([^\\[\\]]*)\\]{1}\\{\\.([^\\{\\}]*)?\\}',
                replace: function (match, content, className) {
                    return '<span class="' + className + '">' + content + '</span>';
                }
            }
            let myLink = {
                type: 'lang',
                regex: '\\[{1}([^\\[\\]]*)\\]\\(([^\\(\\)]*)\\){1}\\{([^\\{\\}]*)?\\}',
                replace: function (match, link, linkUrl, linkAttr) {
                    return '<a href="' + linkUrl + '"' + linkAttr + '>' + link + '</a>';
                }
            };
            let myTable = {
                type: 'output',
                regex: /<table>/g,
                replace: function () {
                    return '<table class="table" cellpadding=0 cellspacing=0>';
                }
            };
            // let myHighlight = {
            //     type: 'lang',
            //     regex: /^`{3}(\w+)([^`]*\n)^`{3}/gm,
            //     replace: function(text, runtime, content){
            //         const runtimeClass = `class="${runtime} language-${runtime}"`;
            //         const p = /plaintext/gm;
            //         let contentCodes = content.trim(); 

            //         if (!p.exec(runtime)) {
            //             contentCodes = hljs.highlightAuto(contentCodes).value
            //         }

            //         return `<pre ${runtimeClass}><code ${runtimeClass}>${contentCodes}</code></pre>`
            //     }
            // };

            let myHighlight = [{
                type: 'lang',
                filter: function (text) {
                    const htmlunencode = function htmlunencode(text) {
                        return (
                            text
                                .replace(/&amp;/g, '&')
                                .replace(/&lt;/g, '<')
                                .replace(/&gt;/g, '>')
                        );
                    };
                    let left = '`{3}(\\w+)',
                        right = '`{3}\\n',
                        flags = 'g',
                        replacement = function (wholeMatch, match, left, right) {
                            // unescape match to prevent double escaping
                            match = htmlunencode(match);
                            match = match.replace(/¨D/g, '$')

                            return left + match + right;
                        };
                    return showdown.helper.replaceRecursiveRegExp(text, replacement, left, right, flags);
                }
            },
            {
                type: 'output',
                filter: function (text) {
                    // use new shodown's regexp engine to conditionally parse codeblocks
                    const htmlunencode = function htmlunencode(text) {
                        return (
                            text
                                .replace(/&amp;/g, '&')
                                .replace(/&lt;/g, '<')
                                .replace(/&gt;/g, '>')
                        );
                    };
                    var left = '<pre><code\\b[^>]*>',
                        right = '</code></pre>',
                        flags = 'g',
                        replacement = function (wholeMatch, match, left, right) {
                            // unescape match to prevent double escaping
                            match = htmlunencode(match);

                            const p = /<pre><code(\b[^>]*)>/gm;
                            const r = p.exec(left);
                            if (r) {
                                left = "<pre" + r[1] + ">" + "<code" + r[1] + ">";
                                const p2 = /plaintext/gm;

                                const r2 = p2.exec(left);
                                if (!r2) {
                                    match = hljs.highlightAuto(match).value;
                                }
                            }
                            return left + match + right;
                        };
                    return showdown.helper.replaceRecursiveRegExp(text, replacement, left, right, flags);
                }
            }
            ]

            // let myHighlight = {
            //     type: 'output',
            //     filter: function (text) {
            //         // use new shodown's regexp engine to conditionally parse codeblocks
            //         const htmlunencode = function htmlunencode(text) {
            //             return (
            //                 text
            //                     .replace(/&amp;/g, '&')
            //                     .replace(/&lt;/g, '<')
            //                     .replace(/&gt;/g, '>')
            //             );
            //         };
            //         var left = '<pre><code\\b[^>]*>',
            //             right = '</code></pre>',
            //             flags = 'g',
            //             replacement = function (wholeMatch, match, left, right) {
            //                 // unescape match to prevent double escaping
            //                 match = htmlunencode(match);

            //                 const p = /<pre><code(\b[^>]*)>/gm;
            //                 const r = p.exec(left);
            //                 if (r) {
            //                     left = "<pre" + r[1] + ">" + "<code" + r[1] + ">";
            //                     const p2 = /plaintext/gm;

            //                     const r2 = p2.exec(left);
            //                     if (!r2) {
            //                         match = hljs.highlightAuto(match).value;
            //                     }
            //                 }
            //                 return left + match + right;
            //             };
            //         return showdown.helper.replaceRecursiveRegExp(text, replacement, left, right, flags);
            //     }
            // };


            //character '¨D' is replacing '$'

            let latexCodeBlocks = []
            let myMathJax = [
                {
                    type: 'lang',
                    regex: /¨D¨D([\s\S]*?)¨D¨D/gm,
                    replace: (match) => {
                        return '\n\n~Z' + (latexCodeBlocks.push({ text: match }) - 1) + 'Z\n\n';
                    }
                },
                {
                    type: 'lang',
                    regex: /¨D([\s\S]*?)¨D/gm,
                    replace: (match) => {
                        match = match.replace('_', '᧿')
                        return '~Z' + (latexCodeBlocks.push({ text: match }) - 1) + 'Z';
                    }
                },
                {
                    type: 'output',
                    regex: '~(Z)(\\d+)\\1',
                    replace: function (match, leadingSlash, index) {
                        index = Number(index);
                        let code = latexCodeBlocks[index].text;
                        code = code.replace(/¨D/g, '$')
                        code = code.replace('᧿', '_')


                        return '<span class="my-math">' + code + '</span>';
                    }
                },
            ];
            latexCodeBlocks = [] // clear cache


            let converter = new showdown.Converter({
                'strikethrough': true,
                'tasklists': true,
                'simpleLineBreaks': true,
                'tables': true,
                extensions: [mySpan, myLink, myTable, myHighlight, myMathJax]
                /*extensions: ['my-span', 'my-link', 'my-table', 'my-highlight']*/
            });

            if (wrapDiv === true) {
                text = "<div>" + text + "</div>";
            }

            // render markdown
            let html = converter.makeHtml(text);

            //html = replaceSafeMath(html, preP1, '᧿', '$')

            return html;
        },
        renderMath() {
            let mathjaxCell = (cell) => {
                // remove any html tag, before render the MathJax
                // const regex = /(<([^>]+)>)/ig
                // let mathContent = cell.innerHTML
                // mathContent = mathContent.replace(regex, "")

                window.MathJax.typesetClear([cell]);
                //cell.innerHTML = mathContent
                window.MathJax.typesetPromise([cell]).then(() => {
                    // the new content is has been typeset
                });
            }
            let cells = document.querySelectorAll('.my-math');
            for (let cell of cells) {
                mathjaxCell(cell);
            }
        },
        copyToClipboard: function (text) {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        toTitleCase(str) {
            str = str.toLowerCase().split(' ');
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            return str.join(' ');
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
    },
    // LifeCycle Hooks
    created() {
    },
    mounted() {
    },
    updated() {
    },
    destroyed() {
    },
};
