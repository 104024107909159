<template>
  <div>
    <v-app-bar dark :prominent="header.prominent" clipped-left app color="cyan">
      <v-app-bar-nav-icon
        v-if="drawerOn"
        @click="switchDrawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-icon>{{ appIcon }}</v-icon>
        {{ appTitle }}
        <div class="camnangjeng-2 font-weight-light" v-if="appSubtitle">
          {{ appSubtitle }}
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn v-if="header.showFind" icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn v-if="header.showMore" icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      <v-btn icon v-if="header.showClose" @click="closeModule()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn v-if="!header.showClose" @click="gohome()" icon>
        <v-icon>mdi-view-module</v-icon>
      </v-btn>
      <v-btn v-if="!header.showSettings" @click="goSettings()" icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      
      <div class="text-center">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          location="end"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar color="cyan">
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ meInfo.userDetails }}</v-list-item-title>
                  <v-list-item-subtitle>{{ meInfo.identityProvider }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                href="/.auth/logout"
              >
                សាញអោត
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
      <template v-slot:extension v-if="header.tabs">
        <v-tabs v-model="headerTabIndex" fixed-tabs>
          <v-tab class="white--text"
            v-for="tabItem in header.tabs"
            @click="goto(tabItem.path)"
            :key="tabItem.title"
          >
            {{ tabItem.title }}
          </v-tab>
        </v-tabs>
      </template>
      <template v-slot:extension v-else-if="header.searching">
        <search-bar @on-search="header.searching"></search-bar>
      </template>
    </v-app-bar>
  </div>
</template>


<style lang="scss">
.camnangjeng-2 {
  font-size: 0.875rem !important;
  line-height: 1.375rem;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { componentHook } from "@/shared/mixins";
import SearchBar from "./SearchBar.vue";
import AuthService from '@/services/auth.service';
//import AuthService from './services/auth.service'

export default {
  name: "HeaderBar",
  mixins: [componentHook],
  computed: {
    ...mapState(["drawer", "closed", "title", "subtitle", "header", "icon"]),
    headerTabIndex: {
      get: function () {
        return this.$store.state.headerTabIndex;
      },
      set: function (tabIndex) {
        this.setHeaderTabIndex(tabIndex);
      },
    },
    appTitle() {
      return this.header.title ?? this.title;
    },
    appSubtitle() {
      return this.header.subtitle ?? this.subtitle;
    },
    appIcon() {
      let icon = this.header.icon ?? this.icon;
      icon = icon ?? process.env.VUE_APP_ICON;
      return icon;
    },
    drawerOn() {
      var header = this.header || {};
      return !header.drawerOff;
    },
  }, 
  async created() {
    this.meInfo = await AuthService.me()
  },
  components: { SearchBar },
  data: () => ({
    headerTab: null,
    menu: false,
    meInfo: {}
  }),
  methods: {
    ...mapActions([
      "setDrawer",
      "setHeader",
      "setLeft",
      "setHeaderTabIndex",
      "setClosed",
      "setIcon",
    ]),
    closeModule() {
      this.setClosed(false);
      this.header.showClose = false;
      if (this.header.onClose) this.header.onClose();
    },
    goto(path) {
      this.$router.push(path).catch((error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(error);
        }
      });
    },
    gohome() {
      this.editHeader({
        icon: null,
        subtitle: null,
        showDrawer: false,
        showClose: false,
        showFind: false,
        showMore: false,
        prominent: false,
        searching: null,
      });
      this.editLeft(null);
      this.setIcon(null);
      this.setDrawer(false)
      this.goto("/");
    },
    
    goSettings() {
      this.editHeader({
        icon: null,
        subtitle: null,
        showDrawer: false,
        showClose: false,
        showFind: false,
        showMore: false,
        prominent: false,
        searching: null,
      });
      this.editLeft(null);
      this.setIcon(null);
      this.setDrawer(false)
      this.goto("/settings");
    },

    switchDrawer() {
      var header = this.header || {};
      if (header.drawerOff) return;
      this.setDrawer(!this.drawer);
    },
  },
};
</script>